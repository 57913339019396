import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  public endpoint = 'http://gonzalolhm.es/commons/';

  constructor(private http: HttpClient) { }

  sendContact(nombre: string, email: string, body: string): Observable<any> {
    const url = this.endpoint + '/mail/send.php';

    let params = new HttpParams();
    params = params.append('messageType', 'contacto');
    params = params.append('nombre', nombre);
    params = params.append('email', email);
    params = params.append('body', body);

    return this.http.get<any>(url, { params });
  }

}
