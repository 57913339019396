import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { BackendService } from './backend.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public contactName = '';
  public contactMail = '';
  public contactBody = '';
  public contactError = false;
  public contactSuccess = false;

  constructor(
    private animateScrollService: NgAnimateScrollService,
    private _backendService: BackendService
  ) {

  }

  ngOnInit() {

  }

  public scrollToDiv(id) {
    this.animateScrollService.scrollToElement(id, 500);
  }

  public toggleVideo(event) {
    if (event.target.currentTime > 0 && !event.target.paused) {
      event.target.load();
    } else {
      event.target.play();
    }
  }

  public sendMail() {
    this.contactError = false;
    this.contactSuccess = false;
    if (this.contactName === '' || this.contactMail === '' || this.contactBody === '') {
      this.contactError = true;
      return;
    }
    this._backendService.sendContact(this.contactName, this.contactMail, this.contactBody)
    .subscribe( (resp: any) => {
      if (resp.status === 200) {
        this.contactSuccess = true;
      } else {
        this.contactError = true;
      }
    });
  }
}
