import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EntryTimecrashldComponent } from './components/blog/entry-timecrashld/entry-timecrashld.component';
import { EntryRobotfactorydownloadsComponent } from './components/blog/entry-robotfactorydownloads/entry-robotfactorydownloads.component';
import { EntryMagicHookDevelopmentComponent } from './components/blog/entry-magic-hook-development/entry-magic-hook-development.component';
import { EntrySecretcodeslaunchingComponent } from './components/blog/entry-secretcodeslaunching/entry-secretcodeslaunching.component';

@NgModule({
  declarations: [
    AppComponent,
    EntryTimecrashldComponent,
    EntryRobotfactorydownloadsComponent,
    EntryMagicHookDevelopmentComponent,
    EntrySecretcodeslaunchingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
