import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entry-secretcodeslaunching',
  templateUrl: './entry-secretcodeslaunching.component.html',
  styleUrls: ['./entry-secretcodeslaunching.component.css']
})
export class EntrySecretcodeslaunchingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
